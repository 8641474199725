import { render, staticRenderFns } from "./ArticleDetail.vue?vue&type=template&id=66213f14&scoped=true&"
import script from "./ArticleDetail.vue?vue&type=script&lang=js&"
export * from "./ArticleDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66213f14",
  null
  
)

export default component.exports